<template>
  <ViewFile
    :display="openViewFile"
    @closeViewFile="closeViewFile"
    :loading="loadingViewFile"
  ></ViewFile>
  <Card>
    <template #content v-if="loading || files.length > 0">
      <div class="grid">
        <div class="col-4">
          <h4>{{ $t("files.title") }}</h4>
        </div>
        <div class="col-8 md:col-2 col-offset-0 md:col-offset-6 text-right">
          <Button v-if="!isMobile" :label="$t('global.export')" @click="exportCSV"></Button>
        </div>
      </div>
      <DataTable
        :value="files"
        :paginator="true"
        :rows="10"
        :loading="loading"
        :filters="filters"
        ref="tableFiles"
        dataKey="id"
        showGridlines
        class="p-datatable-files"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        :currentPageReportTemplate="$tableString"
        removableSort
        breakpoint="1366px"
        sortField="createdDate"
        :sortOrder="-1"
      >
        <template #header>
          <div>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="$t('global.search')"
              />
            </span>
          </div>
        </template>
        <template #empty> {{ $t("files.noFilesFound") }} </template>
        <Column field="id" :header="$t('files.fileId')" :sortable="true">
          <template #body="{ data }">
            <span class="text-right">{{ data.id }}</span>
          </template>
        </Column>
        <Column
          field="tempCreatedDate"
          :header="$t('files.creationDate')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.createdDate }}
          </template>
        </Column>

        <Column
          field="username"
          :header="$t('files.username')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.username }}
          </template>
        </Column>

        <Column
          field="companyName"
          :header="$t('files.company')"
          :sortable="true"
        >
          <template #body="{ data }">
            <span class="text-right md:text-left">{{ data.companyName }}</span>
          </template>
        </Column>

        <Column field="type" :header="$t('files.type')" :sortable="true">
          <template #body="{ data }">
            {{ translateFileTypes(data.type == 'case' ? 'invoice' : data.type) }}
          </template>
        </Column>
        <Column field="name" :header="$t('files.fileName')" :sortable="true">
          <template #body="{ data }">
            {{ data.name }}
          </template>
        </Column>
        <Column field="status" :header="$t('global.status')" :sortable="true">
          <template #body="{ data }">
            {{ translatedStatus(data.status) }}
          </template>
        </Column>
        <Column
          field="Actions"
          :header="$t('global.actions')"
          :exportable="false"
        >
          <template #body="{ data }">
            <div class="flex justify-content-evenly">
              <Button
                class="p-button-text mdi mdi-24px mdi-eye"
                @click="openFile(data)"
              />
              <Button
                class="p-button-text mdi mdi-24px mdi-download"
                @click="downloadFile(data)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
    <template #content v-else-if="!loading">
      <NoTableData :type="'files'"></NoTableData>
    </template>
  </Card>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import NoTableData from "../NoTableData.vue";
import { mapGetters } from "vuex";
import ViewFile from "./ViewFile";
export default {
  name: "Files",
  components: {
    NoTableData,
    ViewFile,
  },
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      loading: false,
      openViewFile: false,
      loadingViewFile: false,
    };
  },
  computed: {
    ...mapGetters(["files", "translatedStatus", "translateFileTypes","isMobile"]),
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("files", {});
    this.loading = false;
    this.$action("files-page-view");
  },
  methods: {
    closeViewFile() {
      this.openViewFile = false;
    },
    exportCSV() {
      this.$refs.tableFiles.exportCSV();
      this.$action("export-files");
    },
    async openFile(data) {
      this.loadingViewFile = true;
      this.openViewFile = true;
      await this.$store.dispatch("downloadFile", { data, download: false }).then(() => {
        this.$action("file-view", { fileId: data.id });
      })
      this.loadingViewFile = false;
    },
    async downloadFile(data) {
      await this.$store.dispatch("downloadFile", { data }).then(() => {
        this.$action("file-download", { fileId: data.id });
      })
    },
  },
};
</script>
